<template>
  <div>
    <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    />
    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(msg_type)="data">
        {{ resolveMsgType(data.value) }}
      </template>

      <template #cell(request_time)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
    } = useItems()
    fetchItems()

    const resolveMsgType = value => {
      if (value === '3') {
        return 'MMS'
      }
      if (value === '6') {
        return 'KAKAO'
      }
      return ''
    }

    return {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,

      resolveMsgType,
    }
  },
})
</script>
