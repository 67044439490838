import {
  computed,
} from '@vue/composition-api'
import data from './data'
import api from './api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: data.currentPage.value,
    size: data.perPage.value,
    total: data.totalRows.value,
    ...data.filterParams.value,
  }))

  const categoryOptions = [
    {
      label: 'TEXT',
      paramName: 'text_list',
    },
    {
      label: 'DSTADDR',
      paramName: 'dstaddr_list',
    },
    {
      label: 'MSG_TYPE',
      paramName: 'msg_type_list',
      format: 'checkbox',
      options: [
        { text: 'MMS', value: 3 },
        { text: 'KAKAO', value: 6 },
      ],
    },
    {
      label: 'REQUEST_TIME',
      paramName: 'request_time_list',
      format: 'date',
    },
  ]

  const fetchItems = () => {
    data.isBusy.value = true
    api.fetchItems({
      ...searchParams.value,
    }).then(response => {
      const resData = response.data
      data.items.value = resData.data
      data.totalRows.value = resData.page_info.total_count
      data.filterParams.value.total = resData.page_info.total_count
      replaceRouterQuery(searchParams.value)
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      data.isBusy.value = false
    })
  }

  const search = params => {
    data.currentPage.value = 1
    data.filterParams.value = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    fetchItems()
  }

  const tableColumns = [
    { key: 'mseq', label: 'mseq' },
    { key: 'dstaddr', label: 'dstaddr' },
    { key: 'msg_type', label: 'msg_type' },
    { key: 'request_time', label: 'request_time' },
    { key: 'text', label: 'text' },
  ]

  const changePage = params => {
    data.currentPage.value = params.page
    fetchItems()
  }

  return {
    categoryOptions,
    ...data,
    fetchItems,
    tableColumns,
    search,
    changePage,
  }
}
